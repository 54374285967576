export enum RecruitFimmStateCodes {
  ADMIN_ASSEGNATO = 'ADMIN_ASSEGNATO', //
  HR_KO_CONTACT_DUPLICATED = 'HR_KO_CONTACT_DUPLICATED', //
  HR_DA_CONTATTARE = 'HR_DA_CONTATTARE', //
  HR_OK_INSERITO = 'HR_OK_INSERITO', //STATO CHE VA DIRETTAMENT "IN GESTIONE"
  HR_OK_FRBP = 'HR_OK_FRBP', //
  HR_NON_RISPONDE = 'HR_NON_RISPONDE', //
  HR_KO_NON_REPERIBILE = 'HR_KO_NON_REPERIBILE', //
  HR_DA_RICHIAMARE = 'HR_DA_RICHIAMARE',
  HR_KO_NON_IN_LINEA = 'HR_KO_NON_IN_LINEA', //
  HR_KO_NO_INTERESSATO = 'HR_KO_NO_INTERESSATO', //
  HR_KO_DISTANZA = 'HR_KO_DISTANZA', //ko zona
  HR_KO_ETA = 'HR_KO_ETA',
  HR_SM_COLLOQUIO_FISSATO = 'HR_SM_COLLOQUIO_FISSATO', //
  SM_OK_STANDBY = 'SM_OK_STANDBY',
  SM_KO_COLLOQUIO_ESEGUITO = 'SM_KO_COLLOQUIO_ESEGUITO', //
  SM_OK_DOCUMENTAZIONE_INVIATA = 'SM_OK_DOCUMENTAZIONE_INVIATA', //
  SM_IN_TRATTATIVA = 'SM_IN_TRATTATIVA', //in trattativa
  SM_OK_IN_GESTIONE = 'SM_OK_IN_GESTIONE',
  SM_KO_CONTRATTO = 'SM_KO_CONTRATTO', //KO contratto
  SM_OK_PREPARAZIONE_CONTRATTO = 'SM_OK_PREPARAZIONE_CONTRATTO',
  SM_OK_ONBOARDING = 'SM_OK_ONBOARDING',
  SM_OPERATIVO = 'SM_OPERATIVO', //confermato
  SM_KO_CHIUSURA_CONTRATTO = 'SM_KO_CHIUSURA_CONTRATTO',
}
export enum RecruitFimmStateLabel {
  HR_KO_CONTACT_DUPLICATED = 'Contatto duplicato', //
  HR_DA_CONTATTARE = 'Da chiamare', //
  HR_OK_INSERITO = 'Ok inserito',
  HR_OK_FRBP = 'Ok FRBP CF', //
  HR_NON_RISPONDE = 'Ko non risponde', //
  HR_KO_NON_REPERIBILE = 'Ko non reperibile/num errato', //
  HR_DA_RICHIAMARE = 'Da Richiamare',
  HR_KO_NO_INTERESSATO = 'Ko non interessato', //
  HR_KO_NON_IN_LINEA = 'Ko non in target', //
  HR_KO_DISTANZA = 'Ko zona', //ko zona
  HR_KO_ETA = 'Ko età',
  HR_SM_COLLOQUIO_FISSATO = 'Colloquio fissato', //
  SM_OK_STANDBY = 'Stand by',
  SM_KO_COLLOQUIO_ESEGUITO = 'Ko dopo colloquio', //
  SM_OK_DOCUMENTAZIONE_INVIATA = 'Bozza documentazione inviata', //
  SM_IN_TRATTATIVA = 'In trattativa/Ok 2° Colloquio ', //in trattativa
  SM_OK_IN_GESTIONE = 'In gestione',
  SM_KO_CONTRATTO = 'Ko contratto', //KO contratto
  SM_OK_PREPARAZIONE_CONTRATTO = 'Contratto da preparare',
  SM_OK_ONBOARDING = 'Ok onboarding',
  SM_OPERATIVO = 'Confermato', //confermato
  SM_KO_CHIUSURA_CONTRATTO = 'Ko chiusura contratto',
}

const hrDef = {
  HR_DA_CONTATTARE: [
    RecruitFimmStateCodes.HR_KO_NON_REPERIBILE,
    RecruitFimmStateCodes.HR_NON_RISPONDE,
    RecruitFimmStateCodes.HR_KO_NO_INTERESSATO,
    RecruitFimmStateCodes.HR_KO_NON_IN_LINEA,
    RecruitFimmStateCodes.HR_KO_DISTANZA,
    RecruitFimmStateCodes.HR_KO_ETA,
    RecruitFimmStateCodes.HR_OK_FRBP,
    RecruitFimmStateCodes.HR_SM_COLLOQUIO_FISSATO,
    RecruitFimmStateCodes.HR_OK_INSERITO,
  ],
  HR_OK_INSERITO: [RecruitFimmStateCodes.SM_OK_IN_GESTIONE],
  HR_KO_NON_REPERIBILE: [RecruitFimmStateCodes.HR_DA_RICHIAMARE],
  HR_NON_RISPONDE: [RecruitFimmStateCodes.HR_DA_RICHIAMARE],
  HR_KO_NO_INTERESSATO: [RecruitFimmStateCodes.HR_DA_RICHIAMARE],
  HR_KO_NON_IN_LINEA: [RecruitFimmStateCodes.HR_DA_RICHIAMARE],
  HR_KO_DISTANZA: [RecruitFimmStateCodes.HR_DA_RICHIAMARE],
  HR_KO_ETA: [RecruitFimmStateCodes.HR_DA_RICHIAMARE],
  HR_OK_FRBP: [RecruitFimmStateCodes.HR_DA_RICHIAMARE],
  HR_DA_RICHIAMARE: [
    RecruitFimmStateCodes.HR_KO_NON_REPERIBILE,
    RecruitFimmStateCodes.HR_NON_RISPONDE,
    RecruitFimmStateCodes.HR_KO_NO_INTERESSATO,
    RecruitFimmStateCodes.HR_KO_NON_IN_LINEA,
    RecruitFimmStateCodes.HR_KO_DISTANZA,
    RecruitFimmStateCodes.HR_KO_ETA,
    RecruitFimmStateCodes.HR_SM_COLLOQUIO_FISSATO,
    RecruitFimmStateCodes.HR_OK_FRBP,
  ],
};
const smDef = {
  HR_SM_COLLOQUIO_FISSATO: [
    RecruitFimmStateCodes.SM_KO_COLLOQUIO_ESEGUITO,
    RecruitFimmStateCodes.SM_OK_STANDBY,
  ],
  SM_OK_STANDBY: [RecruitFimmStateCodes.SM_OK_DOCUMENTAZIONE_INVIATA],
  SM_OK_DOCUMENTAZIONE_INVIATA: [RecruitFimmStateCodes.SM_IN_TRATTATIVA],
  SM_IN_TRATTATIVA: [
    RecruitFimmStateCodes.SM_KO_CONTRATTO,
    RecruitFimmStateCodes.SM_OK_IN_GESTIONE,
  ],
  SM_OK_IN_GESTIONE: [
    RecruitFimmStateCodes.SM_KO_CONTRATTO,
    RecruitFimmStateCodes.SM_OK_PREPARAZIONE_CONTRATTO,
  ],
  SM_OK_PREPARAZIONE_CONTRATTO: [RecruitFimmStateCodes.SM_OK_ONBOARDING],
  SM_OK_ONBOARDING: [
    RecruitFimmStateCodes.SM_KO_CHIUSURA_CONTRATTO,
    RecruitFimmStateCodes.SM_OPERATIVO,
  ],
};
const RecruitFimmStatesDefinition = {
  ADMIN: {
    ADMIN_ASSEGNATO: [
      RecruitFimmStateCodes.HR_DA_CONTATTARE,
      RecruitFimmStateCodes.HR_KO_CONTACT_DUPLICATED,
    ],
    ...hrDef,
    ...smDef,
  },
  HR: hrDef,
  SM: smDef,
};

export function getAllRecruitFimmHRStates() {
  return Object.keys(RecruitFimmStatesDefinition.HR);
}
export function getAllRecruitFimmSMStates() {
  return Object.keys(RecruitFimmStatesDefinition.SM);
}
export function getAllRecruitFimmStates() {
  return getAllRecruitFimmHRStates().concat(getAllRecruitFimmSMStates());
}

export function getPossibleRecruitFimmChoiseStates(
  currentState: RecruitFimmStateCodes,
  role: 'SM' | 'HR' | 'ADMIN'
): RecruitFimmStateCodes[] {
  switch (role) {
    case 'HR':
      return RecruitFimmStatesDefinition.HR[currentState] || [];
    case 'SM':
      return RecruitFimmStatesDefinition.SM[currentState] || [];
    case 'ADMIN':
      return RecruitFimmStatesDefinition.ADMIN[currentState] || [];
    default:
      return [];
  }
}
