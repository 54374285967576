export interface IRole {
  description?: string;
  name: string;
  isDefault?: boolean;
  isSuperRole?: boolean;
}

export enum CommonRoles {
  IS_ADMIN = 'is_admin',
}
export const LabelRoles = {
  is_admin: 'Admin',
  is_area_manager: 'Area manager',
  is_regional_manager: 'Regional Manager',
};
