import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CandidatiApiService } from './candidati-api.service';
import { CandidatiMenuService } from './candidati-menu.service';
import { RecruitFimmEventsApiService } from './recruitfimm-events-api.service';

const DECLARATIONS = [];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule],
  providers: [CandidatiApiService, CandidatiMenuService, RecruitFimmEventsApiService],
  exports: [...DECLARATIONS],
})
export class ServicesModule {}
